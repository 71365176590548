export type ACTIONS =
  | 'read'
  | 'create'
  | 'update'
  | 'delete'
  | 'add'
  | 'changeRole'
  | 'edit'
  | 'grantOwnership'
  | 'invite'
  | 'remove';

export const ENTITLEMENTS = {
  abTests: {
    create: 'ab-tests:create',
    delete: 'ab-tests:delete',
    read: 'ab-tests:read',
    update: 'ab-tests:update',
  },
  account: {
    create: 'account:create',
    delete: 'account:delete',
    read: 'account:read',
    update: 'account:update',
  },
  accountStores: {
    read: 'account-stores:read',
  },
  adminStores: {
    create: 'admin-stores:create',
    delete: 'admin-stores:delete',
    read: 'admin-stores:read',
    update: 'admin-stores:update',
  },
  adminUsers: {
    create: 'admin-users:create',
    delete: 'admin-users:delete',
    read: 'admin-users:read',
    update: 'admin-users:update',
  },
  analytics: {
    read: 'analytics:read',
  },
  apiTokens: {
    create: 'api-tokens:create',
    delete: 'api-tokens:delete',
    read: 'api-tokens:read',
    update: 'api-tokens:update',
  },
  billing: {
    read: 'billing:read',
    update: 'billing:update',
  },
  billingMethod: {
    create: 'billing-method:create',
    delete: 'billing-method:delete',
    read: 'billing-method:read',
    update: 'billing-method:update',
  },
  billingSettings: {
    read: 'billing-settings:read',
    update: 'billing-settings:update',
  },
  branding: {
    read: 'branding:read',
    update: 'branding:update',
  },
  capabilities: {
    read: 'capabilities:read',
    update: 'capabilities:update',
  },
  claim: {
    create: 'claim:create',
    delete: 'claim:delete',
    read: 'claim:read',
    resolve: 'claim:resolve',
    update: 'claim:update',
  },
  claimFiling: {
    read: 'claim-filing:read',
    update: 'claim-filing:update',
  },
  claimNote: {
    create: 'claim-note:create',
    delete: 'claim-note:delete',
    read: 'claim-note:read',
    update: 'claim-note:update',
  },
  contact: {
    read: 'contact:read',
    update: 'contact:update',
  },
  finance: {
    read: 'finance:read',
  },
  financeAdmin: {
    read: 'finance-admin:read',
    update: 'finance-admin:update',
  },
  globalMacro: {
    create: 'global-macro:create',
    delete: 'global-macro:delete',
    read: 'global-macro:read',
    update: 'global-macro:update',
  },
  implementation: {
    read: 'implementation:read',
    deploy: 'implementation:deploy',
    review: 'implementation:review',
    approve: 'implementation:approve',
    reject: 'implementation:reject',
    create: 'implementation:create',
    brandCreate: 'implementation:brand-create',
  },
  invoice: {
    read: 'invoice:read',
  },
  label: {
    add: 'label:add',
    create: 'label:create',
    delete: 'label:delete',
    read: 'label:read',
    remove: 'label:remove',
    update: 'label:update',
  },
  macro: {
    create: 'macro:create',
    delete: 'macro:delete',
    read: 'macro:read',
    update: 'macro:update',
  },
  message: {
    create: 'message:create',
    read: 'message:read',
  },
  metadata: {
    create: 'metadata:create',
    delete: 'metadata:delete',
    read: 'metadata:read',
    update: 'metadata:update',
  },
  notifications: {
    read: 'notifications:read',
  },
  order: {
    create: 'order:create',
    delete: 'order:delete',
    read: 'order:read',
    update: 'order:update',
  },
  ota: {
    read: 'ota:read',
    update: 'ota:update',
  },
  payout: {
    read: 'payout:read',
  },
  paymentMethod: {
    create: 'payment-method:create',
    delete: 'payment-method:delete',
    read: 'payment-method:read',
    update: 'payment-method:update',
  },
  payoutMethod: {
    create: 'payout-method:create',
    delete: 'payout-method:delete',
    read: 'payout-method:read',
    update: 'payout-method:update',
  },
  productVariant: {
    create: 'product-variant:create',
    delete: 'product-variant:delete',
    read: 'product-variant:read',
    update: 'product-variant:update',
  },
  policies: {
    create: 'policies:create',
    delete: 'policies:delete',
    read: 'policies:read',
    update: 'policies:update',
  },
  pricing: {
    create: 'pricing:create',
    read: 'pricing:read',
    update: 'pricing:update',
  },
  protection: {
    add: 'protection:add',
    remove: 'protection:remove',
  },
  quotePricing: {
    create: 'quote-pricing:create',
    delete: 'quote-pricing:delete',
    read: 'quote-pricing:read',
    update: 'quote-pricing:update',
  },
  returns: {
    read: 'returns:read',
    update: 'returns:update',
  },
  settingsClaimAcknowledgment: {
    read: 'settings:general:claim-acknowledgment:read',
    update: 'settings:general:claim-acknowledgment:update',
  },
  settingsCustomerCommunication: {
    read: 'settings:general:customer-communication:read',
    update: 'settings:general:customer-communication:update',
  },
  settingsFulfillment: {
    read: 'settings:general:fulfillment:read',
    update: 'settings:general:fulfillment:update',
  },
  settingsGeneral: {
    read: 'settings:general:read',
  },
  settingsMetadata: {
    read: 'settings:general:metadata:read',
    update: 'settings:general:metadata:update',
  },
  settingsOneTimeAppeasements: {
    read: 'settings:general:one-time-appeasements:read',
    update: 'settings:general:one-time-appeasements:update',
  },
  settingsReplacementCost: {
    read: 'settings:general:replacement-cost:read',
    update: 'settings:general:replacement-cost:update',
  },
  settingsRevShare: {
    read: 'settings:general:rev-share:read',
    update: 'settings:general:rev-share:update',
  },
  settingsWidget: {
    read: 'settings:general:widget:read',
    update: 'settings:general:widget:update',
  },
  slack: {
    createChannel: 'slack:create-channel',
    viewChannel: 'slack:view-channel',
  },
  sop: {
    create: 'sop:create',
    read: 'sop:read',
    update: 'sop:update',
    delete: 'sop:delete',
    setState: 'sop:set-state',
  },
  store: {
    activate: 'store:activate',
    create: 'store:create',
    delete: 'store:delete',
    read: 'store:read',
    update: 'store:update',
  },
  storeCredit: {
    read: 'store-credit:read',
    update: 'store-credit:update',
  },
  support: {
    memberCreate: 'support:member-create',
    memberDelete: 'support:member-delete',
    memberRead: 'support:member-read',
    memberUpdate: 'support:member-update',
  },
  supportUser: {
    create: 'support-user:create',
  },
  teamMember: {
    changeRole: 'team-member:change-role',
    edit: 'team-member:edit',
    grantOwnership: 'team-member:grant-ownership',
    invite: 'team-member:invite',
    read: 'team-member:read',
    remove: 'team-member:remove',
  },
  user: {
    create: 'user:create',
    delete: 'user:delete',
    read: 'user:read',
    update: 'user:update',
  },
  webhook: {
    create: 'webhook:create',
    delete: 'webhook:delete',
    read: 'webhook:read',
    update: 'webhook:update',
  },
  whitelabel: {
    list: 'whitelabel:list',
    restart: 'whitelabel:restart',
  },
};
