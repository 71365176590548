import { ROLES, ROLE_IDS_BRAND, ROLE_IDS_OP } from '@auth';
import { defineStore } from 'pinia';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';

import { auth } from '~/support/constants';
import { UnableToRefreshToken } from '~/support/utils';
import type { LoginResponse, UserInfo } from '~/types';

function parseJwt(token: string | undefined | null) {
  if (!window) return null;
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export const useUserStore = defineStore('user', () => {
  const refreshToken = useCookie(auth.cookies.REFRESH_TOKEN);
  const accessToken = useCookie(auth.cookies.ACCESS_TOKEN);
  const idToken = useCookie(auth.cookies.ID_TOKEN);
  const expiresAt = useCookie(auth.cookies.EXPIRES_AT);
  const { identity } = useGateway();
  const app = useNuxtApp();

  const authenticated = ref(false);
  const user = ref<UserInfo | null>(null);
  const loading = ref(true);

  const account = computed(() => {
    return user.value?.accountId;
  });

  const sessionExpired = computed(() => {
    if (!expiresAt.value) return true;

    return Date.now() > Number(expiresAt.value);
  });

  function loadSession() {
    const decoded = parseJwt(idToken.value);

    user.value = decoded;
    authenticated.value = !!user.value;
  }

  function logout() {
    authenticated.value = false;
    loading.value = false;
    user.value = null;
    refreshToken.value = null;
    accessToken.value = null;
    idToken.value = null;
    expiresAt.value = null;
  }

  function setUser(res: LoginResponse) {
    accessToken.value = res.access_token;
    idToken.value = res.id_token;
    expiresAt.value = String(Date.now() + res.expires_in * 1000);
    refreshToken.value = res.refresh_token ?? refreshToken.value;

    user.value = parseJwt(res.id_token);
    authenticated.value = true;
    loading.value = false;
  }

  function fetchUserInfo() {
    return identity.users.me().pipe(
      filter(res => !!res),
      tap(res => {
        user.value = { ...user.value, ...res.data };
      }),
    );
  }

  function refresh(token?: string) {
    if (!token) {
      return of(null);
    }

    return identity.auth.refresh(refreshToken.value as string).pipe(
      catchError(() => {
        throw new UnableToRefreshToken();
      }),
      tap(res => {
        setUser(res.data);
      }),
    );
  }

  function refreshOrLogout() {
    return refresh(refreshToken.value as string).pipe(
      // @ts-ignore
      catchError(() => {
        logout();

        app.$router.push('/login');

        return of(null);
      }),
      filter(res => !!res),
      switchMap(() => fetchUserInfo()),
    );
  }

  function login({ email, password }: { email: string; password: string }) {
    return identity.auth.login(email, password).pipe(
      map(res => {
        setUser(res.data);

        return res;
      }),
    );
  }

  async function refreshSession() {
    if (!refreshToken.value) {
      throw new Error('User does not have a refresh token');
    }

    if (!authenticated.value) {
      throw new Error('User is not authenticated');
    }
  }

  function sendResetPasswordRequest(email: string) {
    return identity.auth.password.requestChange(email).subscribe();
  }

  function init() {
    loadSession();

    return fetchUserInfo().pipe(
      catchError(() => {
        logout();
        app.$router.push('/login');
        loading.value = false;

        return of(null);
      }),
    );
  }

  const initials = computed(() => {
    if (!user.value?.given_name || !user.value?.family_name) return '';

    return `${user.value.given_name[0]}${user.value.family_name[0]}`;
  });

  const username = computed(() => {
    return user.value?.name;
  });

  const roleName = computed(() => {
    if (!user.value) return null;

    return user.value.role?.name;
  });

  const isAdmin = computed(() => {
    return roleName.value === ROLES.opAdmin || roleName.value === ROLES.opFinance;
  });

  const isOpUser = computed(() => {
    if (!user.value?.role) return false;

    return ROLE_IDS_OP.includes(user.value.role.id) && user.value.role.name.startsWith('orderprotection');
  });

  const isBrandUser = computed(() => {
    if (!user.value?.role) return false;

    return ROLE_IDS_BRAND.includes(user.value.role.id);
  });

  const isGlobalSupport = computed(() => {
    return roleName.value === ROLES.opGlobalSupport;
  });

  return {
    accessToken,
    account,
    authenticated,
    fetchUserInfo,
    init,
    initials,
    isAdmin,
    isBrandUser,
    isGlobalSupport,
    isOpUser,
    loadSession,
    loading,
    login,
    logout,
    refreshOrLogout,
    refreshSession,
    roleName,
    sendResetPasswordRequest,
    sessionExpired,
    setUser,
    user,
    username,
  };
});
