import ApiBase from './base';

export default class ClaimService extends ApiBase {
  async exportClaims(body: unknown) {
    const { data } = await this.client.post(`v1/claims/export-claims`, body);

    return data;
  }

  async exportStoreCredits(body: unknown) {
    const { data } = await this.client.post(`v1/claims/credits/export`, body);

    return data;
  }

  async getStoreCredits(
    storeId: string,
    params?: { type?: string; page?: number; limit?: number; createdAtMin?: Date; createdAtMax?: Date },
  ) {
    const { data } = await this.client.get(`v1/claims/credits/store/${storeId}`, { params });

    return data;
  }

  async getStoreCreditUsagesByOrder(orderId: string) {
    const { data } = await this.client.get(`v1/claims/credits/usages/${orderId}`);

    return data;
  }
}
