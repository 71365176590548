import GatewayBase from './gateway-base';

type Plan = {
  revSharePercentage: number;
  billingCycle: string;
};

export default class BilingRestService extends GatewayBase {
  get plans() {
    return {
      find: this.getPlan,
      update: this.updatePlan,
    };
  }

  get settings() {
    return {
      byStore: this.getBillingSettings,
      update: this.updateBillingSettings,
    };
  }

  private getPlan = (storeId: string) => {
    return this.client.get<Plan>(`v1/billing/${storeId}/plans`);
  };

  private updatePlan = (storeId: string, input: Plan) => {
    return this.client.put<Plan>(`v1/billing/${storeId}/plans`, input);
  };

  private getBillingSettings = (storeId: string) => {
    return this.client.get<{ exemptedEmails: string; id: string }>('v1/billing/settings', { params: { storeId } });
  };

  private updateBillingSettings = (id: string, updates: { exemptedEmails: string }) => {
    return this.client.put<{ exemptedEmails: string }>(`v1/billing/settings/${id}`, updates);
  };
}
