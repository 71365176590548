import GatewayBase from './gateway-base';

export default class ClaimsRestService extends GatewayBase {
  private claimsForOrder = (orderId: string) => {
    return this.client.get<any[]>(`/v1/claims/order/${orderId}`);
  };

  private getPolicyByOrderId = (orderId: string) => {
    return this.client.get<any>(`/v1/policies/${orderId}`);
  };

  private createPolicy = (data: any) => {
    return this.client.post<any>('/v1/policies', data);
  };

  get policies() {
    return {
      create: this.createPolicy,
      forOrder: this.getPolicyByOrderId,
    };
  }
  get claims() {
    return {
      credits: {
        delete: this.deleteStoreCredit,
        usage: {
          get: this.getClaimCreditUsage,
        },
      },
      forOrder: this.claimsForOrder,
    };
  }

  private deleteStoreCredit = (storeCreditId: string, storeId) => {
    return this.client.delete(`/v1/claims/credits/${storeCreditId}`, {
      params: { storeId },
    });
  };

  private getClaimCreditUsage = (orderId: string) => {
    return this.client.get(`/v1/claims/credits/usages/${orderId}`);
  };
}
