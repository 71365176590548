import { request } from '~/support/utils';

/**
 *
 * @deprecated - please use the GatewayBase instead using RXJS
 * The gateway base comes with better error handling.
 */
export default class ApiBase {
  #baseUrl;

  constructor(baseUrl: string) {
    this.#baseUrl = baseUrl;
  }

  get client() {
    return request.create({
      baseURL: this.#baseUrl,
    });
  }
}
