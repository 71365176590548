import { ReturnSettingDto, UpdateReturnSettingDto } from '@returns/dto';

import GatewayBase from './gateway-base';
import { PaginatedResponse } from 'libs/shared/types';
import { Customer, Order } from '@monolog/db/orders';

type OrderItem = {
  id: string;
  currency: string;
  orderId: string;
  productName: string;
  productUrl: string;
  productImage: string;
  itemDescription: string;
  sku: string;
  price: number;
  discount: number;
  tax: number;
  sourceItemId: string;
  sourceProductId: string;
  sourceVariantId: string;
  refundedQuantity: number;
  manuallyAdded: boolean;
  selectedOptions: { name: string; value: string }[];
};

type ReturnItem = {
  id: string;
  returnId: string;
  productName: string;
  orderItem: OrderItem;
  quantity: number;
  reason: string;
  reasonCode: string;
};

export type Return = {
  id: string;
  orderId: string;
  status: string;
  url: string;
  trackingId: string;
  trackingNumber: string;
  trackingCarrier: string;
  trackingUrl: string;
  sourceCreatedAt: string;
  createdAt: string;
  updatedAt: string;
  returnNumber: string;
  items: ReturnItem[];
  labelUrl: string;
  labelCost: string;
  labelCurrency: string;
};

export class OrdersRestService extends GatewayBase {
  get returns() {
    return {
      byOrder: this.getReturnsByOrderId,
      customers: {
        byId: this.getCustomerById,
        byStoreId: this.getAllCustomersByStoreId,
        protectedOrders: this.getCustomerProtectedOrders,
      },
      settings: {
        get: this.getReturnSettings,
        update: this.updateReturnSettings,
      },
    };
  }

  private getReturnsByOrderId = (orderId: string) => {
    return this.client.get<Return[]>(`v1/returns/${orderId}`);
  };

  private getReturnSettings = (storeId: string, provider = 'parcellab') => {
    return this.client.get<ReturnSettingDto>(`v1/returns/${provider}/settings/${storeId}`);
  };

  private updateReturnSettings = (storeId: string, input: UpdateReturnSettingDto, provider = 'parcellab') => {
    return this.client.put<ReturnSettingDto>(`v1/returns/${provider}/settings/${storeId}`, input);
  };

  private getAllCustomersByStoreId = (storeId: string, paginationArgs = {}) => {
    return this.client.get<
      PaginatedResponse<{
        id: string;
        name: string;
        email: string;
        phone: string;
        createdAt: string;
        updatedAt: string;
        dataPoints: {
          insuredOrders: number;
          totalAmount: number;
          totalOrders: number;
        };
      }>
    >(`v1/orders/customers/all`, {
      params: { storeId, ...paginationArgs },
    });
  };

  private getCustomerById = (customerId: string, storeId: string) => {
    return this.client.get<Customer>(`v1/orders/customers/${customerId}`, { params: { storeId } });
  };

  private getCustomerProtectedOrders = (customerId: string, storeId: string, paginationArgs = {}) => {
    return this.client.get<
      PaginatedResponse<{
        Order;
      }>
    >(`v1/orders/customers/${customerId}/protected`, { params: { storeId, ...paginationArgs } });
  };
}
